<template>
  <div class="goods-coupon" v-if="list.length">
    <div class="coupon-list" v-loading="loading">
      <template v-for="(item, index) in list">
        <div class="coupon-item" v-if="index < 2" :key="index">
          <div class="coupon-item-head" v-if="!item.memberOnly">
            <span class="coupon-text">券</span>
          </div>
          <!-- <div class="coupon-item-head-vip" v-if="item.memberOnly">
            <span class="coupon-text">券</span>
          </div> -->
          <div class="coupon-item-body2" v-if="item.picked">
            <span class="coupon-text">{{ item.title }}</span>
          </div>
          <template v-if="!item.picked">
            <div class="coupon-item-body">
              <span class="coupon-text">{{ item.title }}</span>
            </div>
            <div class="coupon-item-foot cursor-pointer" @click="pickCustomerCouponFn(item)">
              <span class="coupon-text">领</span>
            </div>
          </template>
        </div>
      </template>
      <template v-if="list.length > 2">...</template>
    </div>
    <div class="flex flex-row-center cursor-pointer" @click="toCouponCenter">
      领券中心
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import { pickCustomerCoupon } from "@/api/coupon";

export default {
  name: "GoodsDetailsCoupon",
  props: {
    max: {
      type: Number,
      default: 2
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    // 领取优惠券
    pickCustomerCouponFn(data) {
      this.loading = true;
      pickCustomerCoupon(data.id)
        .then(() => {
          this.$message.success("领取成功！");
          // 领取优惠券后重新获取数据
          this.$emit("refresh");
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "加载失败");
        });
    },
    toCouponCenter() {
      this.$router.push("/products/coupons-center");
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.goods-coupon {
  text-align: left;
  font-size: 18px;
  color: #ff6e4c;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  .coupon-list {
    flex: 1;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .coupon-item {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .coupon-text {
        font-size: 16px;
        transform: scale(0.9);
      }
      .coupon-item-head {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 4px;
        font-weight: 700;
        border-radius: 2px 0 0 2px;
        background: rgba(255, 244, 241, 0.99);
        border: 1px solid rgba(#ff6e4c, 0.5);
      }
      // .coupon-item-head-vip {
      //   display: flex;
      //   align-items: center;
      //   box-sizing: border-box;
      //   padding-left: 2px;
      //   width: 36px;
      //   height: 16px;
      //   padding-left: 16px;
      //   background: url('~@/assets/images/coupon/coupon-item-head-vip.png') no-repeat;
      //   background-size: 100% 100%;
      //   font-weight: 700;
      // }
      .coupon-item-body {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 4px;
        background: rgba(255, 244, 241, 0.99);
        border-top: 1px solid rgba(#ff6e4c, 0.5);
        border-bottom: 1px solid rgba(#ff6e4c, 0.5);
      }
      .coupon-item-body2 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 4px;
        border-radius: 0 2px 2px 0;
        background: rgba(255, 244, 241, 0.99);
        border: 1px solid rgba(#ff6e4c, 0.5);
        border-left: none;
      }
      .coupon-item-foot {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 4px;
        font-weight: 500;
        background: #ff6e4c;
        color: #ffffff;
        border-top: 1px solid #ff6e4c;
        border-bottom: 1px solid #ff6e4c;
      }
    }
  }
}
</style>
